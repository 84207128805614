import TopBlock from './top-block';
import Carousel from './carousel';

const DifferentiatorSection = () => {
  return (
    <div className="m-auto max-w-7xl px-4 lg:gap-x-8 lg:px-8">
      <TopBlock />
      <div className="m-auto max-w-xl py-4 text-center font-semibold text-white">
        <h2 className="styled-font text-2xl font-bold">
          Intelligence You Won't Find Anywhere Else
        </h2>
        <p className="py-2  text-base font-normal text-[#c2c3c5]">
          Most comprehensive and easy to use platform ever created to level the playing field for
          all traders{' '}
        </p>
        <div className="border-gradient m-auto h-0.5 w-16 bg-gradient-to-r from-blue-500 to-teal-500"></div>
      </div>
      <div className="py-10">
        <Carousel />
      </div>
    </div>
  );
};

export default DifferentiatorSection;

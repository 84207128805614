import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';

import { COUPON_CODE, BUTTON_TEXT_BANNER, PERCENTAGE } from 'constants/topBannerConfig';

const PromoButton = () => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(COUPON_CODE)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <div className="px-4">
      <div className="relative mx-auto flex max-w-3xl flex-col items-center justify-between gap-2 rounded-2xl border-[3px] border-brand-primary bg-brand-primary/40 p-6 shadow-md md:flex-row">
        <div className="text-2xl font-bold uppercase text-yellow-400">{BUTTON_TEXT_BANNER}</div>
        <div className="ml-3 text-2xl font-bold">SAVE {PERCENTAGE}</div>
        <div className="flex items-center justify-between rounded-lg bg-white px-4 py-2 text-brand-primary">
          <span className="px-2 text-2xl font-semibold">{COUPON_CODE}</span>
          <button
            className="rounded-lg bg-brand-primary px-3 py-1.5 text-sm font-semibold text-white"
            onClick={handleCopy}
          >
            {copied ? <FaCheck color="#fff" /> : 'Copy'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PromoButton;

import CallOutHeadingSection from './Callout';

interface Props {
  buynow?: boolean;
  signup?: boolean;
  heading?: string;
}

export default function TookitSection({ buynow, signup, heading }: Props) {
  return (
    <div>
      <div className="m-auto max-w-5xl px-4 py-10 lg:gap-x-8 lg:px-8">
        {heading && (
          <>
            <h2 className="mb-2 text-center text-xl font-bold">{heading}</h2>
            <div className="border-gradient m-auto h-0.5 w-16 bg-gradient-to-r from-blue-500 to-teal-500"></div>
          </>
        )}

        <div className="mx-auto py-10">
          <img src="/images/tools.png" />
        </div>
      </div>
      <CallOutHeadingSection signup={signup} buynow={buynow} />
    </div>
  );
}

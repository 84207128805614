import { CheckIcon } from '@heroicons/react/solid';

const items2023 = [
  'Best Data Analysis Tool',
  'Best Investment Research',
  'Best For Swing Trading',
  'Best For Trading Cryptocurrency',
  'Breaking Barriers',
];
const items2022 = [
  'Best Day Trading Software',
  'Best New Product (N)',
  'Best Software for Swing Trading (N)',
];

export default function AwardBlock() {
  return (
    <div className="relative isolate py-5">
      <div className="mx-auto mb-20 max-w-5xl space-y-10 px-4 lg:px-8">
        <div className="mx-auto flex max-w-2xl flex-col items-center justify-center gap-8 rounded-3xl border border-gray-300 px-6 py-10 sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:py-8 xl:gap-x-8">
          <div className="flex flex-wrap items-center justify-center lg:w-[56%] lg:max-w-[400px]">
            <img
              className="w-1/2 flex-none rounded-2xl object-cover p-2.5 shadow-xl lg:aspect-square lg:h-auto lg:w-1/3 lg:max-w-sm"
              src="/images/benzinga-2023-1.png"
              alt=""
            />
            <img
              className="w-1/2 flex-none rounded-2xl object-cover p-2.5 shadow-xl lg:aspect-square lg:h-auto lg:w-1/3 lg:max-w-sm"
              src="/images/benzinga-2023-2.png"
              alt=""
            />
            <img
              className="w-1/2 flex-none rounded-2xl object-cover p-2.5 shadow-xl lg:aspect-square lg:h-auto lg:w-1/3 lg:max-w-sm"
              src="/images/benzinga-2023-3.png"
              alt=""
            />
            <img
              className="w-1/2 flex-none rounded-2xl object-cover p-2.5 shadow-xl lg:aspect-square lg:h-auto lg:w-1/3 lg:max-w-sm"
              src="/images/benzinga-2023-4.png"
              alt=""
            />
            <img
              className="w-1/2 flex-none rounded-2xl object-cover p-2.5 shadow-xl lg:aspect-square lg:h-auto lg:w-1/3 lg:max-w-sm"
              src="/images/benzinga-2023-5.png"
              alt=""
            />
          </div>
          <div>
            <ul role="list" className="text-base leading-7 text-white">
              {items2023.map((items) => (
                <li key={items} className="flex items-center gap-x-3 text-sm lg:text-xl">
                  <CheckIcon
                    className="bg-brand-green-primary h-5 w-5 flex-none rounded"
                    aria-hidden="true"
                  />
                  {items}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="mx-auto flex max-w-2xl flex-col items-center justify-center gap-8  rounded-3xl border border-gray-300 px-6  py-10 sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row xl:gap-x-8">
          <img
            className="w-[172px] flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
            src="/images/benzinga-2022-1.png"
            alt=""
          />
          <div>
            <ul role="list" className="text-base leading-7 text-white">
              {items2022.map((items) => (
                <li key={items} className="flex items-center gap-x-3 text-sm lg:text-xl">
                  <CheckIcon
                    className="bg-brand-green-primary h-5 w-5 flex-none rounded"
                    aria-hidden="true"
                  />
                  {items}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

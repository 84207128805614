import React from 'react';
import CallOutHeadingSection from './callout';

interface StatItem {
  id: number;
  name: string;
  desc: string;
  logo: string;
}

interface Props {
  statsItem: StatItem[];
  heading: string;
  calloutHeading: string;
}

const TrackerItems = ({ statsItem, heading, calloutHeading }: Props) => {
  return (
    <div className="mt-32 py-20">
      <div className="mx-auto max-w-7xl px-4 pb-12 lg:px-8">
        <h4 className="styled-font pb-2 text-center text-2xl font-bold text-white">{heading}</h4>
        <div className="border-gradient m-auto h-0.5 w-16 bg-gradient-to-r from-blue-500 to-teal-500"></div>
      </div>
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-4">
        {statsItem.map((item) => (
          <div key={item.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
            <div className="mx-auto text-center">
              <img src={item.logo} className="h-24" alt={item.name} />
            </div>
            <div className="text-[18px] font-semibold tracking-tight text-white">{item.name}</div>
            <div className="text-sm text-[#c2c3c5]">{item.desc}</div>
          </div>
        ))}
      </div>
      {calloutHeading && <CallOutHeadingSection heading={calloutHeading} />}
    </div>
  );
};

export default TrackerItems;

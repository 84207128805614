import React from 'react';
import { useRouter } from 'next/router';
import Link from 'components/Link';
import { TECHNICAL_ANALYSIS_LANDING_PAGE } from 'constants/pages';
import DiscountBox from './DiscountBox';

interface Tier {
  name: string;
  id: string;
  href: string;
  price: number;
  description: React.ReactNode;
  discountphrase: string;
  discount: number;
  mostPopular?: boolean;
  border: string;
}

interface Props {
  pricingTiers: Tier[];
  leadingText?: string;
}

export default function PricingCards({
  pricingTiers,
  leadingText = 'Dashboard plans are all-inclusive.',
}: Props) {
  const router = useRouter();
  const pathname = router?.pathname;

  return (
    <div className="py-10 sm:py-10">
      <div className="mx-auto max-w-7xl px-4 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <p className="mb-3 mt-2 font-space text-4xl font-bold tracking-tight text-white sm:text-5xl">
            Select a Plan
          </p>
          <div className="border-gradient m-auto h-0.5 w-16 bg-gradient-to-r from-blue-500 to-teal-500"></div>
        </div>
        <p className="mx-auto mt-6 max-w-4xl text-center leading-8 text-gray-300">
          {pathname === TECHNICAL_ANALYSIS_LANDING_PAGE ? (
            'Each plan contains full access to the technical analysis system (without the dashboard or extra cost)'
          ) : (
            <>
              {leadingText}{' '}
              <span className="border-b-4 border-brand-primary font-bold text-white">
                No commitment. Upgrade plans to save more or cancel anytime.
              </span>
            </>
          )}
        </p>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {pricingTiers.map((tier) => (
            <div
              key={tier.id}
              className={`border-gradient relative  rounded-3xl bg-gradient-to-r p-0.5 ${tier.border}`}
            >
              {tier?.mostPopular ? (
                <p className="styled-font absolute left-1/2 top-0 z-10 -translate-x-1/2 -translate-y-1/2 transform rounded-lg bg-gradient-to-r from-red-500 to-yellow-500 px-2.5 py-1 text-xs font-bold leading-5 text-white">
                  MOST POPULAR
                </p>
              ) : null}
              <div className="relative h-full rounded-3xl bg-gray-900 p-10">
                <div className="flex items-center justify-center gap-x-4">
                  <h3 className="text-2xl font-[500] leading-8 text-white">{tier.name}</h3>
                </div>
                <div className="py-2 text-5xl font-semibold">
                  <sup className="align-top text-base text-[#5b5d63]">$</sup>
                  {tier.price}
                </div>
                <div className="text-sm leading-6 text-gray-400">{tier.description}</div>

                <DiscountBox discount={tier.discount} text={tier.discountphrase} />

                <Link
                  href={tier.href}
                  aria-describedby={tier.id}
                  className="mt-6 block rounded-md bg-brand-primary px-3 py-4 text-center font-bold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  Buy Now
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import { uniqueId } from 'lodash';
import React, { useState } from 'react';

interface Tab {
  label: string;
  desc?: string;
  content: React.ReactNode;
}

interface ButtonTabsProps {
  tabs: Tab[];
}

const ButtonTabs: React.FC<React.PropsWithChildren<ButtonTabsProps>> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div className="flex-col lg:flex-row">
      <nav
        className="flex flex-col justify-center space-y-2 md:flex-row md:space-y-0 md:space-x-4"
        aria-label="Tabs"
      >
        {tabs.map((tab, index) => (
          <div
            key={uniqueId()}
            onClick={() => handleTabClick(index)}
            className={`${activeTab === index ? 'active' : ''}`}
          >
            <button
              type="button"
              className="h-13 bg-brand-primary hover:text-brand-primary w-full flex-none rounded-md  p-3 text-center  text-base font-semibold leading-6 text-white no-underline  hover:bg-white md:w-52"
            >
              {tab.label}
            </button>
            {tab?.desc && <div className="text-left text-sm">{tab?.desc}</div>}
          </div>
        ))}
      </nav>
      <div className="mt-4 w-full">{tabs[activeTab].content}</div>
    </div>
  );
};

export default ButtonTabs;

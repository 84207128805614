interface Stat {
  id: number;
  name: string;
  value: string;
}

interface Props {
  stats: Stat[];
}

export default function StatsSection({ stats }: Props) {
  return (
    <div className="lg:pb-10">
      <div className="mx-auto max-w-7xl px-4 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden text-left sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.id} className="flex flex-col p-8">
                <dt className="text-xl leading-6 text-[#c2c3c5]">{stat.name}</dt>
                <dd className="text-brand-green-primary order-first pb-3 text-6xl font-semibold tracking-tight">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

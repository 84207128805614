import React from 'react';
import { PRICING_PAGE, SIGNUP_PAGE } from 'constants/pages';
import Link from 'components/Link';

interface Props {
  buynow?: boolean;
  signup?: boolean;
}

export default function CallOutHeadingSection({ buynow = true, signup = true }: Props) {
  return (
    <div className="mt-16 w-full bg-brand-primary">
      <div className="mx-auto max-w-5xl p-8 text-left lg:gap-x-8 lg:p-16">
        <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 lg:grid-cols-12 lg:gap-8 ">
          <div className="tracking-tight text-white lg:col-span-9">
            <h5 className="text-4xl font-bold lg:text-6xl">Earn more money.</h5>
            <p className="mt-2 text-xl text-[#c2c3c5]">
              Institutional-grade tools and analysis, simplified
            </p>
          </div>
          <div className="w-full lg:col-span-3">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
              {buynow && (
                <Link
                  href={PRICING_PAGE}
                  className="flex h-12 items-center justify-center gap-x-1.5 rounded-md bg-palette-black-1 px-3.5 py-2.5 text-center  text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  Buy Now
                </Link>
              )}
              {signup && (
                <Link
                  href={SIGNUP_PAGE}
                  className="flex h-12 items-center justify-center gap-x-1.5 rounded-md bg-white px-3.5 py-2.5 text-center  text-sm font-semibold text-black shadow-sm visited:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  Sign Up
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import VerticalTabs from 'components/Tab/VerticalTabs';
import { DASHBOARD_SECTION } from 'constants/pages';

interface TabItem {
  label: string;
  desc: string;
  content: React.ReactNode;
}

interface Props {
  tabsItems: TabItem[];
  heading: string;
  subheading: string;
}

const CallOutSection = ({ tabsItems, heading, subheading }: Props) => {
  return (
    <div id={DASHBOARD_SECTION} className="w-full bg-brand-primary py-5 lg:pb-10">
      <div className="mx-auto max-w-7xl text-center lg:gap-x-8  lg:px-8">
        <h3 className="px-4 py-5 text-3xl font-bold text-white">{heading}</h3>
        <h4 className="px-r mx-auto max-w-xl px-4 pb-5 text-base">{subheading} </h4>
        <VerticalTabs tabs={tabsItems} />
      </div>
    </div>
  );
};

export default CallOutSection;

import React from 'react';
import { CheckIcon } from '@heroicons/react/solid';
import { uniqueId } from 'lodash';

interface Props {
  rowItems: string[];
}

const PricingGrid = ({ rowItems }: Props) => {
  return (
    <div className="mx-auto  max-w-7xl px-4">
      <h2 className="text-center text-2xl font-semibold">Each plan includes all of our features</h2>
      <div className="mt-4 grid grid-cols-1 gap-4 rounded-xl border border-[#5b5d63] p-8 md:grid-cols-3">
        {rowItems.map((name) => {
          return (
            <div key={uniqueId()} className="flex items-center text-left font-normal leading-tight">
              <CheckIcon className="text-brand-primary h-6 px-3 capitalize" />
              <span className="text-sm">{name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PricingGrid;

import { PlayIcon } from '@heroicons/react/outline';
import Link from 'components/Link';
import { PRICING_SECTION, OPTION_FLOW_PAGE } from 'constants/pages';
import { useModal } from 'hooks/useModal';
import YouTubeLightbox from 'screens/YouTubeLightbox';
import { IS_FREE_FLOW_ENABLED } from 'constants/flags';

interface Props {
  title?: React.ReactNode;
  description?: string;
  heroImageUrl?: string;
  buyNowUrl?: string;
  buyNowButtonText?: string;
}

export default function LandingHeroSection({
  title = (
    <span>
      Options Flow Simplified.
      <br />
      Profits Maximized.
    </span>
  ),
  description = 'Cutting edge tools help you take the guesswork out of trading',
  heroImageUrl = '/images/hero-dashboard.png',
  buyNowUrl = IS_FREE_FLOW_ENABLED ? OPTION_FLOW_PAGE : `#${PRICING_SECTION}`,
  buyNowButtonText = IS_FREE_FLOW_ENABLED ? 'Free Options Flow' : 'Buy Now',
}: Props) {
  const { isOpen, openModal, closeModal } = useModal();

  return (
    <>
      <div className="relative mt-12 pb-16 lg:mt-24">
        <div className="mx-auto flex max-w-[100rem] flex-col gap-12 lg:flex-row lg:pl-16 xl:pl-32">
          <div className="w-full max-w-[31rem] shrink-0 px-4 pb-12 pt-10 sm:pb-32 lg:px-0 lg:pb-24 lg:pt-24">
            <div className="mx-auto max-w-2xl  text-white lg:mx-0">
              <div className="sm:mt-32 sm:flex lg:mt-16">
                <div className="mb-2 text-base font-semibold uppercase text-brand-green-primary">
                  Award-nominated trading toolkit
                </div>
              </div>
              <h2 className="styled-font my-2 text-3xl font-bold text-white md:text-4xl">
                {title}
              </h2>
              <p className="my-2 leading-8 text-gray-300">{description}</p>
              <div className="mt-8 flex flex-col gap-3 sm:flex-row sm:items-center">
                <Link
                  href={buyNowUrl}
                  className="styled-font focus-visible:brand-green-primary rounded-md bg-brand-green-secondary px-12  py-4 text-center text-lg font-bold text-white  no-underline shadow-sm hover:bg-white hover:text-brand-green-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  {buyNowButtonText}
                </Link>
                <button
                  type="button"
                  className="flex items-center rounded-full p-2 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                  onClick={() => openModal()}
                >
                  <PlayIcon className="mr-2 block h-8 w-8" aria-hidden="true" />
                  <div className="styled-font font-bold">Watch A Short Video</div>
                </button>
              </div>
              {IS_FREE_FLOW_ENABLED && (
                <div className="mt-3 flex items-center">
                  <div>Ready for the full experience?</div>
                  <Link
                    href={`#${PRICING_SECTION}`}
                    className="styled-font ml-2 font-bold text-white underline hover:underline"
                  >
                    Buy Now
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="relative flex grow items-center justify-center px-4 md:px-0">
            <img src={heroImageUrl} className="max-h-full animate-spulse rounded-lg" />
          </div>
        </div>
      </div>
      <YouTubeLightbox
        title="InsiderFinance: Level the Playing Field"
        videoId="Uj-T2Ibo7Xg"
        isOpen={isOpen}
        handleClose={closeModal}
      />
    </>
  );
}

import React from 'react';
interface Props {
  heading: string;
}

const LandingQuote = ({ heading }: Props) => {
  return (
    <div className="mx-auto max-w-7xl divide-y divide-white/20 px-4 lg:gap-x-8 lg:px-8">
      <div>&nbsp;</div>
      <h2 className="py-10 text-center text-xl leading-10 tracking-tight text-white">{heading}</h2>
    </div>
  );
};

export default LandingQuote;

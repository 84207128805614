// src/components/YouTubeLightbox.tsx
import React from 'react';
import { Dialog } from '@headlessui/react';

type Props = {
  title: string;
  videoId: string;
  isOpen: boolean;
  handleClose: () => void;
};

export default function YouTubeLightbox({ title, videoId, isOpen, handleClose }: Props) {
  return (
    <Dialog open={isOpen} onClose={handleClose} className="z-60 fixed inset-0 overflow-y-auto">
      <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70 backdrop-blur-sm transition-opacity" />

        <span className="hidden sm:inline-block sm:h-screen sm:align-middle">&#8203;</span>
        <button onClick={handleClose} className="absolute top-0 right-0 mt-4 mr-4">
          <span className="text-2xl text-white">&#10005;</span>
        </button>

        <iframe
          className="absolute top-1/2 bottom-1/2 left-1/2 right-1/2 aspect-video w-full max-w-3xl -translate-x-1/2 -translate-y-1/2 px-4"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          title={title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </Dialog>
  );
}

import { uniqueId } from 'lodash';
import React, { useState } from 'react';

interface Tab {
  label: string;
  desc: string;
  content: React.ReactNode;
}

interface VerticalTabsProps {
  tabs: Tab[];
}

const VerticalTabs: React.FC<React.PropsWithChildren<VerticalTabsProps>> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div className="mt-6 flex flex-col lg:mt-14 lg:flex-row">
      <div className="w-screen overflow-x-hidden lg:w-auto">
        <div className="flex w-full overflow-x-auto px-4 lg:flex-col lg:space-y-2 lg:px-0">
          {tabs.map((tab, index) => (
            <button
              type="button"
              key={uniqueId()}
              onClick={() => handleTabClick(index)}
              className={`hover:bg-white-15 flex w-full max-w-[11.25rem] shrink-0 cursor-pointer flex-col rounded-lg p-4 text-center lg:max-w-[30rem] lg:p-[28px] lg:text-left ${
                activeTab === index ? 'bg-white-15' : ''
              }`}
            >
              <div className="styled-font block w-full rounded text-left font-bold">
                {tab.label}
              </div>
              {tab?.desc && (
                <div className="mt-1 text-left text-[13px] leading-relaxed">{tab?.desc}</div>
              )}
            </button>
          ))}
        </div>
      </div>
      <div className="lg:px- mt-4 w-full px-4 lg:ml-8 lg:mt-0">{tabs[activeTab].content}</div>
    </div>
  );
};

export default VerticalTabs;

import React from 'react';
import ButtonTabs from 'components/Tab/ButtonTabs';
import TradingListView from './trading-list-view';

const TradingSystemTabs: React.FC<React.PropsWithChildren<unknown>> = () => {
  const tabs = [
    {
      label: 'Trading System',
      content: (
        <div className="flex justify-center text-center">
          <img
            src="/images/technical-analysis-phone_ipadair4_spacegrey_portrait-p-1600.png"
            className="sm:max-w-full lg:max-w-lg"
          />
        </div>
      ),
    },
    {
      label: 'Full Chart',
      content: (
        <div className="m-auto w-full max-w-[1080px] text-center">
          <p>
            <img
              src="/images/tradingview-desktop.png"
              className="rounded-lg border-2 border-gray-600 shadow-md"
            />
          </p>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="m-auto mt-28 max-w-7xl px-4 lg:gap-x-8 lg:px-8">
        <div className="m-auto max-w-2xl py-4 text-center text-white">
          <h2 className="styled-font text-2xl font-bold">
            Automated Technical Analysis with Unmatched Accuracy
          </h2>
          <p className="py-2 text-base font-normal text-[#c2c3c5]">
            Proprietary system adjusts itself for any symbol in any asset class with unmatched
            precision to automatically detect optimal entries and exits
          </p>
          <div className="border-gradient m-auto h-1 w-16 bg-gradient-to-r from-blue-500 to-teal-500"></div>
        </div>
        <div className="justify-center gap-2 py-10">
          <ButtonTabs tabs={tabs} />
        </div>
      </div>
      <div className="px-2">
        <TradingListView />
      </div>
    </>
  );
};

export default TradingSystemTabs;

import React from 'react';
import { TopTextStyled } from './styles';

const TopText: React.FC<React.PropsWithChildren<{ heading: string }>> = ({ heading }) => {
  return (
    <TopTextStyled>
      <h1 className="text-block text-2xl font-medium leading-7 text-white sm:truncate sm:text-3xl sm:tracking-tight">
        {heading}
      </h1>
    </TopTextStyled>
  );
};

export default TopText;

import React from 'react';

const TradingListView = () => {
  const features = [
    {
      name: 'Complex Analysis, Simplified',
      description:
        'Series of elaborate indicators predict market trends more accurately than anything else publicly available',
    },
    {
      name: 'Effortless Risk Management',
      description:
        'Stop guessing where to take profits or set stop losses with automatic levels for every signal, adjustable for risk tolerance',
    },
    {
      name: 'Ultimate Trend Confirmation',
      description:
        'Trend Lines, Triangles, and Reversal Diamonds provide multiple confirmations of the market direction',
    },
    {
      name: 'Dual Oscillators for Extra Confidence',
      description:
        'Momentum and Squeeze oscillators confirm your entry and exit signals and eliminate false signals',
    },
    {
      name: 'Swing Signals',
      description:
        'Receive swing alerts with a proven track record of high performance on any symbol in any asset class',
    },
    {
      name: 'Caution Candles & Dots',
      description:
        'Quickly identify trend slowdowns and time the perfect exit with yellow candles and caution dots',
    },
  ];

  return (
    <div className="mx-auto max-w-7xl px-4 lg:gap-x-8 lg:px-8">
      <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        {features.map((feature, index) => (
          <div key={feature.name} className="relative">
            <div className="flex items-start space-x-5">
              <div className="mx-auto flex max-w-screen-sm items-center justify-center">
                <div className="h-10 w-10 rounded-full bg-gradient-to-r from-blue-500 to-teal-500 p-0.5">
                  <div className="back flex h-full w-full items-center justify-center rounded-full bg-gray-900 text-white">
                    {index + 1}
                  </div>
                </div>
              </div>
              <div>
                <dt className="styled-font font-semibold text-white">{feature.name}</dt>
                <dd className="text-[13px] leading-normal text-[#c2c3c5]">{feature.description}</dd>
              </div>
            </div>
          </div>
        ))}
      </dl>
      <div className="mt-8 w-full text-center text-sm text-gray-300">
        <a
          href="https://www.tradingview.com/markets/"
          target="_blank"
          rel="noreferrer noopener"
          className="text-gray-300 underline visited:text-gray-300"
        >
          Markets
        </a>{' '}
        by TradingView
      </div>
    </div>
  );
};

export default TradingListView;

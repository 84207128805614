import React, { useRef } from 'react';
import Slider, { Settings } from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';
import { StarIcon } from '@heroicons/react/solid';

interface Testimonial {
  name: string;
  since: string;
  rating: number;
  body: React.ReactNode;
}

const defaultTestimonials: Testimonial[] = [
  {
    name: 'Khosro T',
    since: 'Member Since March 2021',
    rating: 5,
    body: "InsiderFinance's information has increased my returns 10 times. I have made $702,000 in 5 months using their data.",
  },
  {
    name: 'Bryan B',
    since: 'Member Since December 2020',
    rating: 5,
    body: 'InsiderFinance is a game changer. The realtime option flow in combination with the visualizations help me identify quickly where the smart money is flowing to and what side of the contracts they are betting big money on.',
  },
  {
    name: 'Norman W',
    since: 'Member Since March 2021',
    rating: 5,
    body: (
      <div>
        I just want to say I just tried one day of it today and I am amazed!!! You guys have a loyal
        customer for life!!! <br />
        Do you guys offer referral incentives? I'm going to tell all my friends about this.
      </div>
    ),
  },
  {
    name: 'Dip A',
    since: 'Member Since March 2021',
    rating: 5,
    body: (
      <div>
        I just signed up for your service and I am in love! Thank you for building an amazing tool.{' '}
        <br />
        <br />I especially love the fact that the data is processed and I don't have to dig through
        raw option flow data to find trades.This saves me a lot of time and provides a solid
        foundation for future research.
      </div>
    ),
  },
  {
    name: 'Jonathan T',
    since: 'Member Since March 2021',
    rating: 5,
    body: 'Great platform! Very different than others out there and what I have been using the past few years.',
  },

  {
    name: 'Phuc D',
    since: 'Member Since March 2021',
    rating: 5,
    body: "InsiderFinance has improved my profits from trading. It allows me to take positions with better winning odds. It also gives me confidence knowing that there is 'Smart Money' backing my trade. Highly recommend.",
  },
  {
    name: 'Mark W',
    since: 'Member Since March 2021',
    rating: 5,
    body: "I will say that so far from our conversations you've appeared to hold true to your predictions on how much more effective your service would be compared to others.",
  },
  {
    name: 'Pankaj C',
    since: 'Member Since March 2021',
    rating: 5,
    body: 'Thank you, InsiderFinance! You made a great product and I am looking forward to reaping the benefits.',
  },
  {
    name: 'Eddie M',
    since: 'Member Since March 2021',
    rating: 5,
    body: 'As a member, your communication is top notch, and it’s one of the things I actually appreciate from your service. In addition to that, I love getting data alerts via email.',
  },
];

const Testimonials = ({ testimonials = defaultTestimonials }: { testimonials?: Testimonial[] }) => {
  const sliderRef = useRef<Slider>(null);

  const settings: Settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const starIcons = Array(5).fill(<StarIcon className="h-6 w-6 flex-none" aria-hidden="true" />);

  return (
    <div className="mx-auto w-full max-w-7xl py-20 px-4">
      <h2 className="styled-font text-5xl font-semibold">What our members say </h2>
      <div className="py-2 text-[#c2c3c5]">
        Traders using InsiderFinance share their experience{' '}
      </div>
      <div className="relative">
        <div className="flex pt-14">
          <div style={{ overflow: 'hidden' }}>
            <Slider {...settings} ref={sliderRef}>
              {testimonials.map((item) => (
                <div key={item.name} id={item.name}>
                  <div className="mx-2 flex h-full min-h-[19.5rem] flex-col rounded-2xl bg-[#151720] p-6 text-sm leading-6">
                    <div className="flex gap-x-1 pb-2 text-yellow-500">
                      {starIcons.map((icon, index) => (
                        <React.Fragment key={index}>{icon}</React.Fragment>
                      ))}
                    </div>
                    <blockquote className="grow text-left text-[#c2c3c5]">
                      <div>{item['body']}</div>
                    </blockquote>
                    <figcaption className="mt-4 flex gap-x-4">
                      <div>
                        <div className="text-left font-semibold">{item.name}</div>
                        <div className="text-[#c2c3c5]">{`${item.since}`}</div>
                      </div>
                    </figcaption>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="absolute right-4">
          <div className="flex w-full flex-col items-center">
            <div className="flex justify-center">
              <button
                className="rounded px-3 py-2 text-white"
                onClick={() => sliderRef.current?.slickPrev()}
              >
                <ArrowLeftIcon className="mb-1 h-10 w-16" />
                <div className="from-brand-green-primary to-brand-green-primary border-gradient m-auto h-0.5 w-12 bg-gradient-to-r"></div>
              </button>

              <button
                className="rounded px-3 py-2 text-white"
                onClick={() => sliderRef.current?.slickNext()}
              >
                <ArrowRightIcon className="mb-1 h-10 w-16" />
                <div className="from-brand-green-primary to-brand-green-primary border-gradient m-auto h-0.5 w-12 bg-gradient-to-r"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;

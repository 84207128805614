interface Props {
  heading: string;
}
const CallOutHeadingSection = ({ heading }: Props) => {
  return (
    <div className="bg-brand-primary mt-24 w-full py-8 px-4 lg:pb-10">
      <div className="mx-auto max-w-7xl text-left lg:gap-x-8  lg:px-8">
        <h3 className="py-5 text-center text-2xl text-white sm:text-left">{heading}</h3>
      </div>
    </div>
  );
};

export default CallOutHeadingSection;

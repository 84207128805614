import styled from 'styled-components';

export const TopTextStyled = styled.div`
  padding: 24px;

  .text-block {
    max-width: 920px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 24px;
    line-height: 32px;
  }
`;

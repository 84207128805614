import React from 'react';

const TopBlock = () => {
  return (
    <div className="mx-auto my-20 text-center">
      <div className="border-gradient relative mx-auto  inline-block w-auto rounded-md bg-gradient-to-r from-blue-500 to-teal-500 p-0.5">
        <div className="rounded-md bg-gray-900 py-4 px-6 text-2xl font-bold text-white">
          Everything You Need for Consistent Profits
        </div>
      </div>
    </div>
  );
};

export default TopBlock;

import React from 'react';

interface Props {
  discount: number;
  text: string;
  className?: string;
}

export default function DiscountBox({ text }: Props) {
  return (
    <div>
      <div className="border-brand-primary mt-4 block rounded-md border border-solid bg-gray-800 px-4 py-3 text-center text-xs font-semibold leading-5 text-white">
        <div>All-Inclusive Access</div>
        <div>{text}</div>
      </div>
    </div>
  );
}

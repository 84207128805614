import React from 'react';
import { uniqueId } from 'lodash';

interface FeaturedItem {
  src: string;
  bg: string;
  wrapper?: boolean;
}

const featuredData: FeaturedItem[] = [
  { src: '/images/marketwatch.png', bg: '#333131', wrapper: false },
  { src: '/images/yahoofinance-p-1600.png', bg: '#edebe9', wrapper: true },
  { src: '/images/benzinga.jpeg', bg: '#0d213e', wrapper: false },
  { src: '/images/stocktwits.png', bg: '#20222b', wrapper: true },
  { src: '/images/usatoday-p-1080.png', bg: '#20222b', wrapper: true },
  { src: '/images/apnews.png', bg: '#20222b', wrapper: true },
  { src: '/images/tradingview.svg', bg: '#edebe9', wrapper: true },
  { src: '/images/morningstar.png', bg: '#20222b', wrapper: true },
  { src: '/images/ibt.jpeg', bg: '#000000', wrapper: false },
  { src: '/images/usnewsworldreport.jpeg', bg: '#005da6', wrapper: false },
];

const FeaturedSection: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="mx-auto mt-4 mb-10 max-w-5xl px-4 sm:mt-16 lg:px-8">
      <h2 className="mb-5 text-center text-lg font-semibold leading-8 text-white">As Seen In</h2>
      <div className="grid auto-cols-auto grid-cols-2 gap-x-4 gap-y-4 md:grid-cols-5">
        {featuredData.map((item) =>
          item?.wrapper ? (
            <div
              key={uniqueId()}
              className={`flex min-h-[112px] items-center justify-center rounded-lg p-4`}
              style={{ background: item.bg }}
            >
              <img
                className="h-auto w-64 rounded-lg object-cover"
                src={item.src}
                alt="Featured In"
                width={170}
              />
            </div>
          ) : (
            <img
              key={uniqueId()}
              className="h-full min-h-[112px] w-full rounded-lg object-fill"
              src={item.src}
              alt="Featured In"
            />
          ),
        )}
      </div>
    </div>
  );
};

export default FeaturedSection;

import React, { useState, useEffect } from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';

interface CarouselItem {
  title: string;
  img: string;
  desc: string;
}

const images: CarouselItem[] = [
  {
    title: 'Intelligent Order Flow',
    img: '/images/option-flow-p-2600.png',
    desc: 'Save hours of research with the most accurate and intelligent options and dark pool order flow publicly available, developed by a quant',
  },
  {
    title: 'Industry-Leading Analysis & Visuals',
    img: '/images/multi.png',
    desc: 'Every tool you need to research trends and identify the trades with the highest return potential',
  },
  {
    title: 'Proprietary Indicators & Signals',
    img: '/images/nem-2600.png',
    desc: 'Sophisticated algorithms do the heavy lifting to bring the trades that matter front and center',
  },
  {
    title: 'Unusual Options Activity + Sweep Activity',
    img: '/images/unusual-sweeps.png',
    desc: 'Quickly find the highest conviction trades, unusual options activity, and smart money positions with the most urgency',
  },
  {
    title: 'One-Click Setups + Powerful Filters',
    img: '/images/commandcenter.png',
    desc: 'Filter the dashboard with a few simple clicks or use Actionable Presets for a no guesswork approach',
  },
  {
    title: 'Unmatched Market Context & News Sentiment',
    img: '/images/chatter.png',
    desc: 'Easily understand market sentiment + news sentiment and identify sectors with the highest expectation of movement',
  },
];

const Carousel: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 8000);

    return () => {
      clearInterval(interval);
    };
  }, [currentSlide]);

  const currentImage = images[currentSlide];

  const imageStyle: React.CSSProperties = {
    transform: `translateX(${currentSlide * -100}%)`,
    transition: 'transform 0.5s ease-in-out',
    width: '100%',
    display: 'flex',
  };

  return (
    <div className="mx-auto w-full py-10 text-center">
      <div className="relative">
        <div className="max-w-xl text-left">
          <h6 className="styled-font mb-1 text-xl font-semibold">{currentImage.title}</h6>
          <div className="max-w-md text-sm text-[#c2c3c5]">{currentImage.desc}</div>
        </div>
        <div className="absolute top-4 right-4 hidden lg:block">
          <div className="flex w-full flex-col items-center">
            <div className="flex justify-center">
              <button className="rounded px-3 py-2 text-white" onClick={prevSlide}>
                <ArrowLeftIcon className="h-10 w-16" />
              </button>
              <button className="rounded px-3 py-2 text-white" onClick={nextSlide}>
                <ArrowRightIcon className="h-10 w-16" />
              </button>
            </div>
            <div className="flex justify-center">
              {images.map((_, index) => (
                <div
                  key={index}
                  className={`mx-2 h-2 w-2 cursor-pointer rounded-full ${
                    index === currentSlide ? 'bg-brand-primary' : 'bg-gray-300'
                  }`}
                  onClick={() => setCurrentSlide(index)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="pt-10" style={{ overflow: 'hidden' }}>
            <div style={imageStyle}>
              {images.map((image, index) => (
                <img
                  className="h-full max-h-full w-auto max-w-full rounded-xl"
                  key={index}
                  src={image.img}
                  alt={`Slide ${index + 1}`}
                  style={{ flex: '0 0 100%', maxWidth: '100%' }}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="mt-2 block lg:hidden">
          <div className="flex w-full flex-col items-end">
            <div className="flex justify-center pr-2">
              <button className="rounded  py-2 text-white" onClick={prevSlide}>
                <ArrowLeftIcon className="h-10 w-16" />
              </button>
              <button className="rounded  py-2 text-white" onClick={nextSlide}>
                <ArrowRightIcon className="h-10 w-16" />
              </button>
            </div>
            <div className="flex justify-center">
              {images.map((_, index) => (
                <div
                  key={index}
                  className={`mx-2 h-2 w-2 cursor-pointer rounded-full ${
                    index === currentSlide ? 'bg-brand-primary' : 'bg-gray-300'
                  }`}
                  onClick={() => setCurrentSlide(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
